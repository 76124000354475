<template>
  <div>
    <layout :course="'FRM'"></layout>
  </div>
</template>

<script>
import layout from "../../components/warehouse/layout.vue";

export default {
  components: {
    layout,
  },
};
</script>

<style></style>
